import { Match, ParentComponent, Switch } from 'solid-js'

type Props = {
	type?: number
}

const Header: ParentComponent<Props> = props => {
	return (
		<Switch>
			<Match when={props.type === 0}>
				<h1 class="title">{props.children}</h1>
			</Match>
			<Match when={props.type === 1}>
				<h2 class="title">{props.children}</h2>
			</Match>
			<Match when={props.type === 2}>
				<h3 class="title">{props.children}</h3>
			</Match>
			<Match when={props.type === 3}>
				<h4 class="title">{props.children}</h4>
			</Match>
			<Match when={props.type === 4}>
				<h2 class="title h2-light">{props.children}</h2>
			</Match>
			<Match when={props.type === 5}>
				<h2 class="title mb-0">{props.children}</h2>
			</Match>
			<Match when={props.type === 6}>
				<h4 class="title">{props.children}</h4>
			</Match>
			<Match when={props.type === 7}>
				<h3 class="title h4">{props.children}</h3>
			</Match>
		</Switch>
	)
}

export default Header
